import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import Client from './Client';
import Facture from './Facture';
import '@fontsource/archivo-black';
import {jwtDecode} from 'jwt-decode';

const Ajouter = () => {
  const [currentPage, setCurrentPage] = useState('facture');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getTokenFromLocalStorage = () => {
      return localStorage.getItem('accessToken');
    };

    const token = getTokenFromLocalStorage();
    
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded); 
      } catch (err) {
        console.error('Token invalide', err);
        setUser(null);
      }
    } else {
      setUser(null); 
    }
  }, []);

  const renderContent = () => {
    switch (currentPage) {
      case 'facture':
        return <Facture />;
      case 'client':
        return <Client />;
      default:
        return <Facture />;
    }
  };

  return (
    <Box>
      {user ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Typography
              variant="h6"
              onClick={() => setCurrentPage('facture')}
              sx={{
                cursor: 'pointer',
                padding: '10px 20px',
                borderBottom: currentPage === 'facture' ? '3px solid #1976d2' : 'none',
                fontFamily: 'Archivo Black, sans-serif'
              }}
            >
              Facture
            </Typography>
            <Typography
              variant="h6"
              onClick={() => setCurrentPage('client')}
              sx={{
                cursor: 'pointer',
                padding: '10px 20px',
                borderBottom: currentPage === 'client' ? '3px solid #1976d2' : 'none',
                fontFamily: 'Archivo Black, sans-serif'
              }}
            >
              Client
            </Typography>
          </Box>

          <Container sx={{ padding: 3 }}>
            {renderContent()}
          </Container>
        </>
      ) : (
        <Typography variant="body1" sx={{ fontFamily: 'Archivo Black, sans-serif', textAlign: 'center' }}>
          Veuillez vous connecter.
        </Typography>
      )}
    </Box>
  );
};

export default Ajouter;
